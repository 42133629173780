import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import I18n from "i18n-js";
import _ from "lodash";

import logo from "../../assets/Navigation/logo.svg";
import dashboardIcon from "../../assets/Navigation/dashboard.svg";
import notebookIcon from "../../assets/Navigation/notebook.svg";
import geniusWriterIcon from "../../assets/Navigation/genius-writer.svg";
import pdpIcon from "../../assets/Navigation/pdp.svg";
import dataLabIcon from "../../assets/Navigation/data-lab.svg";

import UserDropdown from "./UserDropdown";

const getNavItems = () => [
  {
    title: I18n.t("navigation.dashboard"),
    icon: dashboardIcon,
    href: [
      "/",
      "/dashboard",
      "/dashboard/personal",
      /^\/teams\/(\S)+\/dashboard(\/)?$/,
      "/teams",
      /^\/teams\/(\S)+\/users(\/)?$/,
      /^\/settings(\/)?(\S)*?$/
    ],
    active: true
  },
  {
    title: I18n.t("navigation.notebook"),
    icon: notebookIcon,
    href: [
      "/notebook",
      "/notebook/new",
      "/notebook/personal",
      /^\/teams\/(\S)+\/notebook(\/)?(\S)*?$/,
      /^\/notebook\/personal\/(\S)+$/
    ],
    active: true
  },
  {
    title: I18n.t("navigation.genius_writer"),
    icon: geniusWriterIcon,
    href: "/writer",
    active: true
  },
  {
    title: I18n.t("navigation.pdp"),
    icon: pdpIcon,
    href: "/pdp",
    active: true
  },
  {
    title: I18n.t("navigation.data_lab"),
    icon: dataLabIcon,
    href: "/datalab",
    active: true
  }
];


const Navigation = ({ user, showNavItems, showActionItems, fixed }) => {

  const pathname = location.pathname;
  const currentPath = (pathname.substring(pathname.indexOf("/")) || "");

  return (
    <nav className="navbar">
      <div className="logo">
        {fixed ? (
          <img src={logo} alt="logo" />
        ) : (
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        )}
      </div>
      {fixed || showNavItems === false ? null : (
        <ul className="nav-items">
          {getNavItems().map((item, index) => {
            const href = [item.href].flat();
            
            const selected = href.some(url => {
              if (url instanceof RegExp) {
                return url.test(pathname);
              }
              
              return url === currentPath;
            });

            const className = selected ? "nav-item selected" : "nav-item";
            
            return item.active ? (
              <li key={index} className={className}>
                <a href={href[0]}>
                  <ReactSVG className="icon" src={item.icon} />
                  {item.title}
                </a>
              </li>
            ) : null;
          })}
        </ul>
      )}
      {fixed || showActionItems === false ? null : (
        <div className="action-items">
          <UserDropdown user={user} />
        </div>
      )}
    </nav>
  );
};

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class NavigationComponent extends Component {
  render() {
    return <Navigation {...this.props} />;
  }
}
