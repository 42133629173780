import React from "react";

import closeIcon from "../../../assets/icons/close.svg";

const Chip = ({ value, onDelete }) => (
  <div className="chip">
    {value}
    <img
      className="icon" 
      src={closeIcon}
      alt="Delete"
      onClick={onDelete}
    />
  </div>
);

export default Chip;
