import React, { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import FormSection from "./FormSection";
import ObjectiveList from "./ObjectiveList";

const ObjectivesAndTasks = () => {

  const { control, setValue, clearErrors, formState: { errors } } = useFormContext();
  //const { fields, append, remove } = useFieldArray({ control, name: "objectives" });

  return (
    <FormSection
      title="Objectives & Tasks"
      description="Set objectives and at least one practical task to achieve them."
    >
      <ObjectiveList
        errors={errors.objectives}
        onChange={objectives => setValue("objectives", objectives)}
        onErrorClear={clearErrors}
      />
    </FormSection>
  );
}

export default ObjectivesAndTasks;
