import React, { useState } from "react";
import Progress from "react-svg-progress";
import toast from "react-hot-toast";
import I18n from "i18n-js";
import _ from "lodash";

import api from "../../../api";

import InternalModal from "../../../commons/InternalModal";

import Input from "./Input";
import EmailsInput from "./EmailsInput";

const ShareModal = ({ note, show, onCancel, onShared }) => {

  const [loading, setLoading] = useState(false);

  const [emails, setEmails] = useState([]);
  const [emailsError, setEmailsError] = useState("");
  const [subject, setSubject] = useState(I18n.t("email.note.share_note.subject"));
  const [message, setMessage] = useState("");

  const shareNote = () => {
    setLoading(true);
    api.post(`/notes/${note.uuid}/share`, { emails, subject, message })
      .then(res => {
        console.log(res);
        setTimeout(() => {
          toast.success(<b>{I18n.t("common.shared_successfully")}</b>);
          clearFormState();
          onShared && onShared();
        }, 500);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        setLoading(false);
        console.error(err);
      });
  }

  const handleEmailsOnValueAdded = (email) => {
    setEmails(emails => [...emails, email]);
    setEmailsError("");
  }

  const handleEmailsOnValueRemoved = (index) => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);
  }

  const handleEmailsOnChange = () => {
    if (emailsError !== "") setEmailsError("");
  }

  const handleSubjectOnChange = (subject) => {
    setSubject(subject);
  }

  const handleMessageOnChange = (message) => {
    setMessage(message);
  }

  const handleSendOnClick = (event) => {
    event.preventDefault();

    if (_.isEmpty(emails)) {
      setEmailsError(I18n.t("notebook.modals.share.inputs.emails.errors.invalid_value"));
      return;
    }

    shareNote();
  }

  const handleCancelOnClick = (event) => {
    event.preventDefault();
    clearFormState();
    onCancel && onCancel();
  }

  const clearFormState = () => {
    setLoading(false);
    setEmails([]);
    setEmailsError("");
    setSubject(I18n.t("email.note.share_note.subject"));
    setMessage("");
  }

  const renderModal = () => {
    return (
      <div className="share-modal">
        <div className="title">{I18n.t("notebook.modals.share.title")}</div>
        <EmailsInput
          label={I18n.t("notebook.modals.share.inputs.emails.label")}
          placeholder={I18n.t("notebook.modals.share.inputs.emails.placeholder")}
          values={emails}
          error={emailsError}
          onValueAdded={handleEmailsOnValueAdded}
          onValueRemoved={handleEmailsOnValueRemoved}
          onChange={handleEmailsOnChange}
        />
        <Input
          label={I18n.t("notebook.modals.share.inputs.subject.label")}
          placeholder={I18n.t("notebook.modals.share.inputs.subject.placeholder")}
          value={subject}
          onChange={handleSubjectOnChange}
        />
        <Input
          label={I18n.t("notebook.modals.share.inputs.message.label")}
          placeholder={I18n.t("notebook.modals.share.inputs.message.placeholder")}
          rows={3}
          value={message}
          onChange={handleMessageOnChange}
        />
        <div className="buttons">
          <button
            className="cancel-btn"
            disabled={loading}
            onClick={handleCancelOnClick}
          >
            {I18n.t("common.cancel")}
          </button>
          <button
            className={`send-btn ${loading ? "loading" : ""}`}
            disabled={loading}
            onClick={handleSendOnClick}
          >
            <span className="content">
              {I18n.t("notebook.modals.share.buttons.send.title")}
            </span>
            <Progress color="#FFFFFF" size="15px" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <InternalModal
      type="share-note"
      size="lg"
      isModalOpen={show}
      hideModal={() => onCancel && onCancel()}
      component={renderModal()}
      showModalBody={false}
    />
  );
}

export default ShareModal;
