import React, { Component } from "react";
import I18n from "i18n-js";

import useRoutingState from "../hooks/useRoutingState";

import ComingSoon from "../commons/ComingSoon";

const Pdp = () => {
  useRoutingState();

  return (
    <div className="pdp">
      <div className="container-fluid-coming-soon">
        <ComingSoon
          title={I18n.t("pdp.features.title")}
          features={[
            I18n.t("pdp.features.first"),
            I18n.t("pdp.features.second"),
            I18n.t("pdp.features.third"),
            I18n.t("pdp.features.fourth")
          ]}
        />
      </div>
    </div>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class PdpComponent extends Component {
  render() {
    return <Pdp {...this.props} />;
  }
}
