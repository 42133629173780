import React from "react";
import I18n from "i18n-js";

const SelectedPdpTitle = ({ selectedTeam, teams, personal }) => {
  const team = selectedTeam || teams[0];

  return (
    <div className="selected-pdp-title">
      {personal
        ? I18n.t("pdp.selector.personal_pdp")
        : I18n.t("pdp.selector.pdp", { team: team.name })
      }
    </div>
  );
}

export default SelectedPdpTitle;
