import React, { useEffect, useRef, useState } from "react";
import isHotkey from "is-hotkey";
import _ from "lodash";

import { validateEmail, validateUniqueness } from "../../services/validation";

import Chip from "./Chip";

const KEYS = ["space", "enter"];

const EmailsInput = ({
  id,
  label,
  placeholder,
  values,
  error: inputError,
  onValueAdded,
  onValueRemoved,
  onChange
}) => {
  const finalId = id || _.uniqueId();

  const [currentValue, setCurrentValue] = useState("");
  const [error, setError] = useState("");

  const inputContainer = useRef();
  const inputWrapper = useRef();
  const input = useRef();
  const widthMachine = useRef();

  useEffect(() => {
    setError(inputError);
  }, [inputError]);

  useEffect(() => {
    if (_.isEmpty(values) && currentValue == "") {
      inputWrapper.current.style.minWidth = "500px";
    } else {
      inputWrapper.current.style.minWidth = "unset";
    }
  }, [values, currentValue]);

  useEffect(() => {
    function updateHiddenContent() {
      widthMachine.current.innerHTML = input.current.value;
    }

    if (input.current) {
      input.current.addEventListener("keyup", updateHiddenContent);
    }

    return () => input.current.removeEventListener("keyup", updateHiddenContent);
  }, []);

  const handleOnInputChange = (event) => {
    event.preventDefault();
    setCurrentValue(event.target.value);
    onChange && onChange();
    if (error !== "") setError("");
  }

  const handleOnInputKeyDown = (event) => {
    const value = event.target.value
    if (isHotkey(KEYS, { byKey: true })(event)) {
      event.preventDefault();

      inputContainer.current.scrollLeft += 99999;

      if (!validateUniqueness(values, value)) {
        setError("This email has already been provided")
        return;
      }

      if (!validateEmail(value)) {
        setError("This isn't a valid email")
        return;
      }
      
      onValueAdded && onValueAdded(value);
      setCurrentValue("");
    }
  }

  const handleOnValueDelete = (index) => {
    onValueRemoved && onValueRemoved(index);
  }

  return (
    <div className="input-group emails-input">
      <label htmlFor={finalId}>{label}</label>
      <div ref={inputContainer} className="input">
        {values.map((value, index) => (
          <Chip
            key={index}
            value={value}
            onDelete={() => handleOnValueDelete(index)}
          />
        ))}
        <span ref={inputWrapper} className="input-wrapper">
          <span ref={widthMachine} className="width-machine" aria-hidden="true">
            {currentValue}
          </span>
          <input
            ref={input}
            id={finalId}
            type="email"
            placeholder={values.length ? "" : placeholder}
            value={currentValue}
            onChange={handleOnInputChange}
            onKeyDown={handleOnInputKeyDown}
          />
        </span>
      </div>
      <div className="error">
        {error}
      </div>
    </div>
  );
}

export default EmailsInput;
