import React, { Component, Fragment, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDebounce } from "use-debounce";
import _ from "lodash";

import api from "../api";

import useRoutingState from "../hooks/useRoutingState";

import NotebookSelector from "./components/NotebookSelector";
import SearchField from "./components/SearchField";
import NewNoteButton from "./components/NewNoteButton";
import Notes from "./components/Notes";
import NoteEditor from "./components/NoteEditor";

const Notebook = ({
  notes: initialNotes,
  note: initialNote,
  user,
  teams,
  team,
  personal
}) => {
  useRoutingState();

  const queryParameters = new URLSearchParams(window.location.search);
  const initialSearchTerm = queryParameters.get("search") || "";

  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [searchTermDebounced] = useDebounce(searchTerm, 1000);
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState(initialSearchTerm ? [] : initialNotes);
  const [selectedNote, setSelectedNote] = useState(initialNote);

  useEffect(() => {
    if (_.isEmpty(searchTermDebounced)) {
      setNotes(initialNotes);
      setSelectedNote(initialNote);
      return;
    }
    
    search();
  }, [searchTermDebounced]);

  const search = () => {
    setLoading(true);
    api.get(`/notes?search=${searchTermDebounced}`)
      .then(res => {
        console.log(res);

        const notes = res.data.notes;
        setNotes(notes)
        
        const found = notes.find(n => n.uuid === initialNote.uuid);
        if (!found) setSelectedNote(notes[0]);

        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        setLoading(false);
        console.error(err);
      });
  }

  const handleSearchTermOnChange = (term) => {
    setSearchTerm(term);
  }

  const renderSelector = () => (
    <div className="notebook-row selector">
      <NotebookSelector
        user={user}
        teams={teams}
        selectedTeam={team}
        personal={personal}
      />
    </div>
  );

  const renderSidePanel = () => (
    <div className="side-panel">
      <SearchField
        loading={loading}
        value={searchTerm}
        onChange={handleSearchTermOnChange}
      />
      <Notes
        notes={notes}
        note={selectedNote}
        searchTerm={searchTermDebounced}
      />
    </div>
  );

  const renderPrimaryPanel = () => (
    <div className="primary-panel">
      <NewNoteButton />
      <NoteEditor note={selectedNote} />
    </div>
  );

  return (
    <Fragment>
      <div className="notebook">
        <div className="container-fluid">
          {renderSelector()}
          <div className="notebook-row content">
            {renderSidePanel()}
            {renderPrimaryPanel()}
          </div>
        </div>
      </div>
      <Toaster
        position="top-right"
        reverseOrder
      />
    </Fragment>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class NotebookComponent extends Component {
  render() {
    return <Notebook {...this.props} />;
  }
}
