import { object, string, date, array, number, boolean } from "yup";

const schema = object({
  title: string().required("Label is required"),
  start_date: date().required("Start date is required"),
  end_date: date().required("End date is required"),
  objectives: array().of(
    object({
      index: number().required("Objective index is required"),
      title: string().required("Objective title is required"),
      tasks: array().of(
        object({
          index: number().required("Task index is required"),
          title: string().required("Task title is required"),
          target_date: date().required("Task target date is required")
        })
      ).required("At least one task is required")
       .min(1, "At least one task is required")
    })
  ).required("At least one objective is required")
   .min(1, "At least one objective is required"),
  frequency: number()
    .required("Meet frequency is required")
    .positive("Meet frequency should be a positive value")
    .integer("Meet frequency should be an integer value"),
  periodicity: string().required("Meet periodicity is required"),
  repeat: array().of(string()),
  ending: object({
    type: string().required("Ending type is required"),
    on: date().when("type", {
      is: type => type === "on",
      then: field => field.required("End date is required"),
      otherwise: field => field.notRequired(),
    }),
    after: number().when("type", {
      is: type => type === "after",
      then: field => field
        .required("Occurences count is required")
        .positive("Occurences count should be a positive value")
        .integer("Occurences count should be an integer value"),
      otherwise: field => field.notRequired(),
    }),
  }).required(),
  visible: boolean().required()
}).required();

export default schema;