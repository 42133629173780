import React from "react";
import _ from "lodash";

const Input = ({ id, label, placeholder, value, error, rows, onChange }) => {
  const finalId = id || _.uniqueId();

  const handleOnChange = (event) => {
    event.preventDefault();
    onChange && onChange(event.target.value);
  }

  return (
    <div className="input-group">
      <label htmlFor={finalId}>{label}</label>
      <div className="input">
        {rows ? (
          <textarea
            id={finalId}
            rows={rows}
            placeholder={placeholder}
            value={value}
            onChange={handleOnChange}
          />
        ) : (
          <input
            id={finalId}
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={handleOnChange}
          />
        )}
      </div>
      <div className="error">
        {error}
      </div>
    </div>
  );
}

export default Input;
