import React, { useState, useEffect, useRef } from "react";
import { ReactSVG } from "react-svg";
import I18n from "i18n-js";

import { signout } from "../../services/session";

import Dropdown from "../../commons/Dropdown";

import profileIcon from "../../assets/Navigation/profile.svg";

const UserDropdown = ({ user }) => {

  const [showDropdown, setShowDropdown] = useState(false);

  const ref = useRef();
  
  useEffect(() => {
    const handler = (event) => {
      if (showDropdown && ref.current && !ref.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    }
  }, [showDropdown]);

  const toggleDropdown = () => {
    setShowDropdown(prev => !prev);
  }

  return (
    <div className="user-dropdown show" ref={ref}>
      <button
        className="action-btn"
        aria-haspopup="true"
        aria-expanded={showDropdown ? "true" : "false"}
        onClick={toggleDropdown}
      >
        <ReactSVG className="icon" src={profileIcon} />
        {user.full_name}
      </button>
      <Dropdown
        show={showDropdown}
        submenu={[
          {
            title: I18n.t("navigation.settings"),
            url: "/settings/personal"
          },
          {
            title: I18n.t("navigation.logout"),
            onClick: signout
          }
        ]}
      />
    </div>
  );
}

export default UserDropdown;
