import React, { Component, Fragment, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Toaster } from "react-hot-toast";
import Progress from "react-svg-progress";
import moment from "moment";

import useRoutingState from "../hooks/useRoutingState";

import DateInput from "../commons/DateInput";
import Toggle from "../commons/Toggle";

import Input from "./components/Input";
import SelectedPdpTitle from "./components/SelectedPdpTitle";
import FormSection from "./components/Forms/FormSection";
import ObjectivesAndTasks from "./components/Forms/ObjectivesAndTasks";
import TimeWithVicky from "./components/Forms/TimeWithVicky";

import schema from "./config/validation/new";

const defaultValues = {
  title: "",
  start_date: null,
  end_date: null,
  objectives: [],
  frequency: "1",
  periodicity: "week",
  repeat: [],
  ending: {
    type: "manual",
    on: null,
    after: "4"
  },
  visible: false
};

const New = ({ teams, team, personal }) => {
  useRoutingState();

  const [loading, setLoading] = useState(false);

  const form = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { control, watch, handleSubmit, formState: { errors } } = form;

  console.log(watch(), errors);

  const today = moment().format("YYYY-MM-DD");

  const handleSaveClick = (data) => {
    setLoading(true);
    console.log(data);
  }

  const handleCancelClick = (event) => {

  }

  const renderPageTitle = () => (
    <div className="pdp-row page-title">
      <SelectedPdpTitle
        teams={teams}
        selectedTeam={team}
        personal={personal}
      />
    </div>
  );

  const renderPageContent = () => (
    <div className="page-content">
      <FormProvider {...form} defaultValues={defaultValues}>
        <form onSubmit={handleSubmit(handleSaveClick)}>
          <div className="title">Create your plan</div>
          <FormSection>
            <div className="pdp-form-row">
              <Controller
                name="title"
                control={control}
                render={({ field: { ref, ...props } }) => (
                  <Input
                    placeholder="Plan Label"
                    error={errors.title?.message}
                    inputRef={ref}
                    {...props}
                  />
                )}
              />
              <div className="dummy" />
            </div>
          </FormSection>
          <div className="separator" />
          <FormSection
            title="Timeframe"
            description="Set a timeframe for your plan."
          >
            <div className="pdp-form-row">
              <Controller
                name="start_date"
                control={control}
                render={({ field: { ref, ...props } }) => (
                  <DateInput
                    placeholder="Start Date"
                    error={errors.start_date?.message}
                    inputRef={ref}
                    {...props}
                  />
                )}
              />
              <Controller
                name="end_date"
                control={control}
                render={({ field: { ref, ...props } }) => (
                  <DateInput
                    placeholder="End Date"
                    min={today}
                    error={errors.end_date?.message}
                    inputRef={ref}
                    {...props}
                  />
                )}
              />
            </div>
          </FormSection>
          <div className="separator" />
          <ObjectivesAndTasks />
          <div className="separator" />
          <TimeWithVicky />
          <div className="separator" />
          <FormSection title="Visibility">
            <div className="pdp-inlined-item">
              Make plan visible to managers
              <Controller
                name="visible"
                control={control}
                render={({ field: { ref, ...props } }) => (
                  <Toggle inputRef={ref} {...props} />
                )}
              />
            </div>
          </FormSection>
          <div className="buttons">
            {/* <button
              className="cancel-btn"
              disabled={loading}
              onClick={handleCancelClick}
            >
              {I18n.t("common.cancel")}
            </button> */}
            <button
              type="submit"
              className={`save-btn ${loading ? "loading" : ""}`}
              disabled={loading}
            >
              <span className="content">
                Create Plan
              </span>
              <Progress color="#FFFFFF" size="15px" />
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );

  return (
    <Fragment>
      <div className="pdp">
        <div className="container-fluid">
          {renderPageTitle()}
          <div className="pdp-row">
            {renderPageContent()}
          </div>
        </div>
      </div>
      <Toaster
        position="top-right"
        reverseOrder
      />
    </Fragment>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class NewComponent extends Component {
  render() {
    return <New {...this.props} />;
  }
}