import React, { forwardRef, useEffect } from "react";
import Colorful from "@uiw/react-color-colorful";

import useScrollBlock from "../../hooks/useScrollBlock";

const ColorPicker = forwardRef(({ color, position, onChange }, ref) => {

  const [blockScroll, allowScroll] = useScrollBlock();

  useEffect(() => {
    blockScroll();
    return ()=> allowScroll();
  }, []);

  return (
    <div ref={ref} className="color-picker" style={{ ...position }}>
      <Colorful
        color={color}
        disableAlpha
        onChange={(color) => onChange && onChange(color.hex)}
      />
    </div>
  );
});

export default ColorPicker;
