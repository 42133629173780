import React from "react";
import I18n from "i18n-js";

import ActionButton from "../../commons/ActionButton";

import saveIcon from "../../assets/icons/edit-confirm.svg";

const NotesHeader = ({ canSave, loading, onSaveClick }) => {

  const handleOnSaveClick = () => {
    onSaveClick && onSaveClick();
  }

  return (
    <div className="notes-header">
      <div className="title">
        {I18n.t("notebook.notes")}
      </div>
      <div className="buttons">
        <ActionButton
          title={I18n.t("notebook.actions.save")}
          icon={saveIcon}
          loading={loading}
          disabled={!canSave}
          onClick={handleOnSaveClick}
        />
      </div>
    </div>
  );
}

export default NotesHeader;
