import React from "react";
import ProgressBar from "react-customizable-progressbar";
import I18n from "i18n-js";

import logo from "../../../bundles/assets/Navigation/logo.svg";

const Progress = ({ currentStep, team }) => {

  const value = Math.ceil(currentStep * 6.25);

  const showProgress = () => {
    if (value == 0) {
      return 0;
    } else if (value > 0) {
      return value;
    }
  }

  const renderProgressIndicator = () => {
    if (value == 0) {
      return <div className="welcome">{I18n.t("onboarding.welcome")}</div>
    } else {
      return (
        <div className="step">
          <div className="score-percent">
            <div className="score">{value}</div>
            <div className="percent">%</div>
          </div>
          <div className="label">{I18n.t("onboarding.complete")}</div>
        </div>
      );
    }
  }

  const progressIndicator = renderProgressIndicator();
  const progressValue = showProgress();

  return (
    <div className="progress-section">
      <img className="logo" src={logo} alt="logo" />
      {team ? (
        <span className="team">{team.name}</span>
      ) : null}
      <ProgressBar
        radius={58}
        progress={progressValue}
        strokeWidth={7}
        strokeColor="#78C0B6"
        transition=".3s ease"
        trackStrokeWidth={2}
        trackTransition=".0s"
        trackStrokeColor="#C2C2C2"
        pointerRadius={4} 
        pointerStrokeWidth={3}
        pointerFillColor="#ffffff"
        pointerStrokeColor="#78C0B6"
        children={progressIndicator}
      />
    </div>
  );
}

export default Progress;