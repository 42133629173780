import React, { Component, Fragment } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-hot-toast";
import I18n from "i18n-js";

import { FullAccessRoles } from "../../config/roles";

import api from "../../api";

import UserRemovalConfirmation from "./UserRemovalConfirmation";

import EditIcon from "../../assets/images/edit.svg";
import UserRemovalIcon from "../../assets/images/user-removal.svg";
import ResendInvitationIcon from "../../assets/images/resend-invitation.svg";

export default class UserActions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showUserRemovalConfirmation: false,
      disableUserRemovalConfirmButton: false
    };
  }

  onRemoveUserButtonClick = (event) => {
    event.preventDefault();
    this.setState({ showUserRemovalConfirmation: true });
  }

  onUserRemovalConfirmationCancel = () => {
    this.setState({ showUserRemovalConfirmation: false });
  }

  onUserRemovalConfirmationConfirm = () => {
    const { currentTeam, user } = this.props;

    this.setState({ disableUserRemovalConfirmButton: true });

    api.delete(`/teams/${currentTeam.uuid}/users/${user.uuid}`)
      .then(res => {
        this.setState({ showUserRemovalConfirmation: false });
        toast.success(<b>{I18n.t("user.invite_revoked")}</b>);
        console.log(res);
        if (res.data && res.data.redirectTo) {
          setTimeout(() => {
            window.location.assign(res.data.redirectTo);
          }, 400);
        }
      })
      .catch(err => {
        this.setState({ disableUserRemovalConfirmButton: false });
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        console.error(err);
      });
  }

  onResendInvitationButtonClick = (event) => {
    event.preventDefault();
    
    const { currentTeam, user } = this.props;

    api.post(`/teams/${currentTeam.uuid}/users/${user.uuid}/invite`)
      .then(res => {
        toast.success(<b>{I18n.t("user.invite_sent")}</b>);
        console.log(res);
        if (res.data && res.data.redirectTo) {
          setTimeout(() => {
            window.location.assign(res.data.redirectTo);
          }, 400);
        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        console.error(err);
      });
  }

  render() {
    const { user, loggedUser, openUserEdit } = this.props;
    
    const loggedUserHasAccess = FullAccessRoles.includes(loggedUser.role_id);
    
    const showInvitationActions = user.invitation && !Boolean(user.invitation.accepted);

    if (!loggedUserHasAccess) { return null; }

    return (
      <Fragment>
        <td className="d-flex justify-content-start align-items-center team-actions user-action">
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip id="tooltip-edit">
                {I18n.t("user.edit")}
              </Tooltip>
            }
          >
            <button
              className="mr-2 user-action"
              onClick={() => openUserEdit(user)}
            >
              <img src={EditIcon}></img>
            </button>
          </OverlayTrigger>
          {showInvitationActions ? (
            <Fragment>
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip id="tooltip-revoke-invitation">
                    {I18n.t("user.revoke_invitation")}
                  </Tooltip>
                }
              >
                <button
                  className="mr-2 user-action"
                  type="button"
                  onClick={this.onRemoveUserButtonClick}
                >
                  <img src={UserRemovalIcon}></img>
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip id="tooltip-resend-invitation">
                    {I18n.t("user.resend_invitation")}
                  </Tooltip>
                }
              >
                <button
                  className="user-action"
                  type="button"
                  onClick={this.onResendInvitationButtonClick}
                >
                  <img src={ResendInvitationIcon}></img>
                </button>
              </OverlayTrigger>
            </Fragment>
          ) : null}
        </td>
        <UserRemovalConfirmation
          show={this.state.showUserRemovalConfirmation}
          disableConfirmButton={this.state.disableUserRemovalConfirmButton}
          onConfirm={this.onUserRemovalConfirmationConfirm}
          onCancel={this.onUserRemovalConfirmationCancel}
        />
      </Fragment>
    );
  }
}
