import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import _ from "lodash";

import api from "../../api";

import Editor, { EmptyState } from "../../commons/Editor";

import Header from "./Header";
import Viewer from "./Viewer";
import NotesHeader from "./NotesHeader";

const NoteEditor = ({ note }) => {

  const [loading, setLoading] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [showSave, setShowSave] = useState(false);

  const selectedNote = (note || {}).vicky ? note.sub_note : note;
  
  const title = useRef();
  const content = useRef();

  const saveNote = () => {
    setLoading(true);

    const data = {
      title: title.current,
      content: content.current,
      parent_note_id: (note || {}).id
    };

    const request = selectedNote ? {
      url: `/notes/${selectedNote.uuid}`,
      method: "put"
    } : {
      url: "/notes",
      method: "post"
    };

    api.request({ ...request, data })
      .then(res => {
        console.log(res);
        setTimeout(() => {
          toast.success(<b>{I18n.t("common.saved_successfully")}</b>);

          if (res.data && res.data.redirectTo) {
            window.location.assign(res.data.redirectTo);
          }
        }, 500);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        setLoading(false);
        console.error(err);
      });
  }

  const deleteNote = () => {
    api.delete(`/notes/${note.uuid}`)
      .then(res => {
        console.log(res);
        setTimeout(() => {
          toast.success(<b>{I18n.t("common.deleted_successfully")}</b>);

          if (res.data && res.data.redirectTo) {
            window.location.assign(res.data.redirectTo);
          }
        }, 500);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        console.error(err);
      });
  }

  const handleOnTitleChange = (value) => {
    title.current = value;

    if (!showSave) { setShowSave(true); }

    const isNotEmpty = !_.isEqual(value, "");
    const hasSomeChange = !selectedNote || value !== selectedNote.title;
    
    const save = isNotEmpty && hasSomeChange;
    if (canSave !== save) { setCanSave(save); }
  }

  const handleOnContentChange = (value) => {
    content.current = JSON.stringify(value);

    if (!showSave) { setShowSave(true); }

    const isNotEmpty = !_.isEqual(value, EmptyState);
    const hasSomeChange = !selectedNote || content.current !== selectedNote.content;
    
    const save = isNotEmpty && hasSomeChange;
    if (canSave !== save) { setCanSave(save); }
  }

  const handleOnSaveClick = () => {
    const noteContent = content.current;

    if (_.isEmpty(noteContent) ||
        _.isNull(noteContent) ||
        _.isUndefined(noteContent)) {
      return;
    }
    
    saveNote();
  }

  const handleOnDeleteClick = () => {
    deleteNote();
  }

  const renderVickyNoteEditor = () => (
    <div className="note-editor vicky">
      <Header
        note={note}
        onDeleteClick={handleOnDeleteClick}
      />
      <Viewer note={note} />
      <NotesHeader
        canSave={canSave}
        loading={loading}
        onSaveClick={handleOnSaveClick}
      />
      <Editor
        note={note.sub_note}
        onChange={handleOnContentChange}
      />
    </div>
  );

  const renderManualNoteEditor = () => (
    <div className="note-editor">
      <Header
        note={note}
        showSave={showSave}
        canSave={canSave}
        loading={loading}
        onTitleChange={handleOnTitleChange}
        onSaveClick={handleOnSaveClick}
        onDeleteClick={handleOnDeleteClick}
      />
      <Editor
        note={note}  
        onChange={handleOnContentChange}
      />
    </div>
  );

  return note && note.vicky
    ? renderVickyNoteEditor()
    : renderManualNoteEditor();
}

export default NoteEditor;
