import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { createEditor, Editor as EditorUtils, Node, Operation, Text } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import { withHistory } from "slate-history";
import isHotkey from "is-hotkey";
import I18n from "i18n-js";

import EditorService from "../../services/EditorService";

import Toolbar from "./Toolbar";
import UndoButton from "./UndoButton";
import RedoButton from "./RedoButton";
import AlignmentButton from "./AlignmentButton";
import ColorButton from "./ColorButton";
import MarkButton from "./MarkButton";
import BlockButton from "./BlockButton";
import ClearFormattingButton from "./ClearFormattingButton";
import Leaf from "./Leaf";
import Element from "./Element";
import Placeholder from "./Placeholder";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+s": "strikethrough",
  "mod+´": "code",
  "mod+x": "clear"
};

export const EmptyState = [
  {
    type: "paragraph",
    children: [{ text: "" }]
  },
];

const wordCount = (text) => {
  return text.replace(/[\t\n\r\.\?\!]/gm, " ")
    .split(" ")
    .map(s => s.trim())
    .filter(s => s != "" && s != "null")
    .length;
}

const wordCountForEditor = (editor) => {
  let length = 0;

  for (const [node] of Node.nodes(editor)) {
    if (Text.isText(node)) {
      length += wordCount(node.text);
    }
  }
  
  return length;
}

const withMaxWords = (editor, maxWords) => {
  const { apply } = editor;

  editor.apply = (operation) => {
    const isAstChange = operation.type !== "set_selection";
    apply(operation);

    if (isAstChange && maxWords !== undefined && maxWords > 0 && wordCountForEditor(editor) > maxWords) {
      const undo = Operation.inverse(operation);
      apply(undo);
    }
  };

  return editor;
}

const Editor = forwardRef(({ note, value, placeholder, maxWords, onChange, onWordCountChange }, ref) => {
  const editor = useMemo(() => withHistory(withReact(withMaxWords(createEditor(), maxWords))), []);

  const initialValue = useMemo(() => {
    return note ? JSON.parse(note.content) : EmptyState;
  }, [note]);

  const finalPlaceholder = placeholder != null || placeholder != undefined
    ? placeholder
    : I18n.t("notebook.inputs.content.placeholder");

  const [counter, setCounter] = useState(0);

  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderPlaceholder = useCallback(props => <Placeholder {...props} />, []);

  useEffect(() => {
    if (value) {
      editor.children = JSON.parse(value);
      editor.onChange();
      calculateWordCount();
    }
  }, [value]);

  useEffect(() => {
    onWordCountChange && onWordCountChange(counter);
  }, [counter]);

  const calculateWordCount = () => {
    const count = wordCountForEditor(editor);
    setCounter(count);
  }

  const handleOnKeyDown = (event) => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];

        if (mark === "clear") {
          EditorService.clearFormatting(editor);
        } else {
          EditorService.toggleMark(editor, mark);
        }
      }
    }
  }

  const handleOnChange = (value) => {
    const isValidChange = editor.operations
      .some(op => "set_selection" !== op.type);

    EditorUtils.normalize(editor, { force: true });

    if (isValidChange) {
      onChange && onChange(value);
    }
  }

  return (
    <div ref={ref} className="editor">
      <Slate
        editor={editor}
        initialValue={initialValue}
        onChange={handleOnChange}
      >
        <Toolbar>
          <UndoButton />
          <RedoButton />
          <div className="separator" />
          <BlockButton format="heading-one" icon="heading-one" />
          <BlockButton format="heading-two" icon="heading-two" />
          <div className="separator" />
          <AlignmentButton />
          <div className="small-separator" />
          <ColorButton />
          <div className="separator" />
          <MarkButton format="bold" icon="bold" />
          <MarkButton format="italic" icon="italic" />
          <MarkButton format="underline" icon="underline" />
          <MarkButton format="strikethrough" icon="strikethrough" />
          <MarkButton format="code" icon="code" />
          <ClearFormattingButton />
          <div className="separator" />
          <BlockButton format="block-quote" icon="quotes" />
          <BlockButton format="bulleted-list" icon="bulleted-list" />
          <BlockButton format="numbered-list" icon="numbered-list" />
        </Toolbar>
        <div className="editor-content">
          <Editable
            className="editable"
            placeholder={finalPlaceholder}
            renderLeaf={renderLeaf}
            renderElement={renderElement}
            renderPlaceholder={renderPlaceholder}
            onKeyDown={handleOnKeyDown}
          />
        </div>
      </Slate>
    </div>
  );
});

export default Editor;
