import React from "react";

const Chip = ({ label, value, selected, disabled, onClick }) => {

  const handleClick = (event) => {
    if (disabled) return;
    event.preventDefault();
    onClick && onClick({ label, value });
  }

  return (
    <div
      className={`chip ${selected && !disabled ? "selected" : ""} ${disabled ? "disabled" : ""}`}
      onClick={handleClick}
    >
      <span data-label={label}><p>{label}</p></span>
    </div>
  );
}

export default Chip;
