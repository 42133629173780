import React, { Fragment, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import toast from "react-hot-toast";
import I18n from "i18n-js";
import _ from "lodash";

import InternalModal from "../../../../commons/InternalModal";
import ActionButton from "../../../../commons/ActionButton";

import ItemList from "./ItemList";
import Divider from "./Divider";

const defaultPlans = [
  { title: "2024 Q2 Development Plan", created_at: "2024-05-12", completed_at: "2024-08-12" },
  { title: "2024 Q1 Development Plan", created_at: "2024-01-01", completed_at: "2024-08-15" },
  { title: "2024 Q3 Development Plan", created_at: "2024-08-12", completed_at: "2024-08-15" },
];

const defaultTasks = [
  { index: 2, title: "Read \"The Art of Persuasion\" book", target_date: "2024-08-15", status: "in_progress"},
  { index: 1, title: "Attend Concur Conference", completed_at: "2024-08-15", status: "completed"},
  { index: 4, title: "Attend Another Conference", target_date: "2024-08-15", status: "not_started"},
  { index: 3, title: "Meet with Coach", target_date: "2024-08-15", status: "not_started"},
];

const defaultObjectives = [
  { index: 1, title: "Become a master communicator", tasks: defaultTasks, completed_at: "2024-08-15" },
  { index: 2, title: "Another awesome objective", tasks: defaultTasks, target_date: "2024-08-15" },
  { index: 4, title: "Yet another objective I want to achieve in the sheetz", tasks: defaultTasks, target_date: "2024-08-15" },
  { index: 3, title: "Yet another objective I want to achieve", tasks: defaultTasks, target_date: "2024-08-15" },

  { index: 7, title: "Yet another objective I want to achieve", tasks: defaultTasks, target_date: "2024-08-15" },
  { index: 6, title: "Another awesome objective", tasks: defaultTasks, target_date: "2024-08-15" },
  { index: 8, title: "Yet another objective I want to achieve in the sheetz", tasks: defaultTasks, target_date: "2024-08-15" },
  { index: 5, title: "Become a master communicator", tasks: defaultTasks, completed_at: "2024-08-15" },
];


const CopyItemModal = ({ show, type, onSelect, onCancel }) => {

  const [plans, setPlans] = useState([]);
  const [objectives, setObjectives] = useState([]);
  const [tasks, setTasks] = useState([]);

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [loadingPlansDebounced] = useDebounce(loadingPlans, 15);

  const [selectedObjective, setSelectedObjective] = useState(null);
  const [loadingObjectives, setLoadingObjectives] = useState(false);
  const [loadingObjectivesDebounced] = useDebounce(loadingObjectives, 15);

  const [selectedTask, setSelectedTask] = useState(null);

  const task = type === "task";
  const selected = task ? Boolean(selectedTask) : Boolean(selectedObjective);

  useEffect(() => {
    setTimeout(() => {
      const plans = _.orderBy(defaultPlans, ["created_at"], ["desc"]);
      setPlans(plans);
      setLoadingPlans(false);
    }, 1500);
  }, []);

  const getObjectives = async () => {
    setLoadingObjectives(true);
    setTimeout(() => {
      const objectives = _.orderBy(defaultObjectives, ["index"]);
      setObjectives(objectives);
      setLoadingObjectives(false);
    }, 1500);
  }

  const resetState = () => {
    setLoadingPlans(false);
    setLoadingObjectives(false);
    setSelectedPlan(null);
    setSelectedObjective(null);
    setSelectedTask(null);
  }

  const handlePlanSelected = async (plan) => {
    if (selectedPlan === plan) return;
    setSelectedPlan(plan);
    await getObjectives();
  }

  const handleObjectiveSelected = async (objective) => {
    if (selectedObjective === objective) return;
    setSelectedObjective(objective);
    // TODO: Sort when received from backend!
    setTasks(_.sortBy(objective.tasks, ["index"]));
  }

  const handleSelectClick = () => {
    const item = task ? selectedTask : selectedObjective;
    onSelect && onSelect(item);
    resetState();
  }

  const handleCancelClick = (event) => {
    event.preventDefault();
    resetState();
    onCancel && onCancel();
  }

  const renderModal = () => {
    return (
      <div className="pdp-copy-item-modal">
        <div className="title">
          {task
            ? I18n.t("pdp.modals.copy-item.title.task")
            : I18n.t("pdp.modals.copy-item.title.objective")
          }
        </div>
        <div className="subtitle">{I18n.t("pdp.modals.copy-item.subtitle")}</div>
        <div className="lists">
          <ItemList
            title="Development Plans"
            expandable
            items={plans}
            loading={loadingPlansDebounced}
            onItemSelected={handlePlanSelected}
          />
          <Divider show={Boolean(selectedPlan)} />
          {selectedPlan ? (
            <ItemList
              title="Objectives"
              expandable
              items={objectives}
              loading={loadingObjectivesDebounced}
              onItemSelected={handleObjectiveSelected}
            />
          ) : (
            <div className="list-dummy" />
          )}
          <Divider show={Boolean(selectedObjective)} />
          {selectedObjective ? (
            <ItemList
              title="Tasks"
              items={tasks}
              disabled={!task}
              onItemSelected={setSelectedTask}
            />
          ) : (
            <div className="list-dummy" />
          )}
        </div>
        <div className="buttons">
          <button
            className="cancel-btn"
            onClick={handleCancelClick}
          >
            {I18n.t("common.cancel")}
          </button>
          <ActionButton
            filled
            disabled={!selected}
            className="select-btn"
            title={I18n.t("pdp.modals.copy-item.buttons.select.title")}
            onClick={handleSelectClick}
          />
        </div>
      </div>
    );
  }

  return (
    <InternalModal
      type="copy-item"
      size="lg"
      isModalOpen={show}
      hideModal={() => onCancel && onCancel()}
      component={renderModal()}
      showModalBody={false}
    />
  );
}

export default CopyItemModal;
