import React, { forwardRef, Fragment } from "react";
import _ from "lodash";

const Toggle = forwardRef(({
  className,
  inputRef,
  id,
  value,
  showIndicator,
  disabled,
  onChange
}, ref) => {
  const finalId = id || _.uniqueId();

  const handleChange = (event) => {
    event.preventDefault();
    onChange && onChange(event);
  }

  return (
    <div ref={ref} className={`${className} toggle custom-switch custom-switch-sm switch`}>
      <input
        id={finalId}
        ref={inputRef}
        className="custom-switch-input"
        type="checkbox"
        checked={value}
        onChange={handleChange}
        disabled={disabled}
      />
      <label className="custom-switch-btn" htmlFor={finalId} />
      {showIndicator ? (
        <Fragment>
          <div className="custom-switch-content-checked">
            {I18n.t("common.active")}
          </div>
          <div className="custom-switch-content-unchecked">
            {I18n.t("common.inactive")}
          </div>
        </Fragment>
      ) : null}
    </div>
  );
});

export default Toggle;
